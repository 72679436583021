<template>
  <div class="vTipsLabel">
    <div class="vTipsLabel__title">
      Чаевые
    </div>
    <p class="vTipsLabel__desc">
      Здесь вы можете добавить других получателей чаевых, помимо официанта. Максимальное количество получателей - 2.
    </p>
    <p class="vTipsLabel__desc">
      После добавления, вам необходимо каждому получателю привязать банковскую карту сотрудника, на которую будут приходить чаевые. Также можете загрузить фото и указать цель накоплений.
    </p>
    <div class="vTipsLabel__info">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733"
          stroke="#80869A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.95 17.1171C12.548 17.1171 12.99 16.6491 12.99 16.0771C12.99 15.5051 12.548 15.0371 11.95 15.0371C11.352 15.0371 10.91 15.5051 10.91 16.0771C10.91 16.6491 11.352 17.1171 11.95 17.1171ZM11.17 13.6201C11.17 14.0509 11.5192 14.4001 11.95 14.4001C12.3808 14.4001 12.73 14.0509 12.73 13.6201V7.78C12.73 7.34922 12.3808 7 11.95 7C11.5192 7 11.17 7.34922 11.17 7.78V13.6201Z"
          fill="#413DFF"
        />
      </svg>
      Данный функционал работает только в основном сервисе. Не работает для чаевых на пречеке
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vTipsLabel {
  position: absolute;
  left: calc(100% + 32px);
  top: 0;
  max-width: 346px;
  width: 100%;
  margin-bottom: 24px;
  @media (max-width: 1100px) {
    position: static;
    max-width: 100%;
  }
  &__title {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 12px;
  }
  &__desc {
    color: #4E5672;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 18px;
    line-height: 18px;
  }
  &__info {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #80869A;
    display: flex;
    margin-top: 32px;
    svg {
      min-width: 24px;
      min-height: 24px;
      margin-right: 12px;
    }
  }
}
</style>
