import { $authHost } from './api'
import { AxiosResponse } from 'axios'

interface IChangeAvatarResponse {
  success: boolean
  result: null | TeamData
}

interface TeamData {
  id: number
  name: string
  role: string
  shop_id: number
  avatar_link: number
  purpose: string
  card_verification: boolean
  active: boolean
  avatar: string
  card_linked: {
    id: number
    token: string
    item_id: number
    item_type: string
    number_card: string
    tips_link: string
  }
}
export interface ISavePayload {
  role: string
  name: string
  purpose: string
  active: boolean
  visible: boolean
}

class TeamService {
  async fetch() {
    try {
      const response: AxiosResponse = await $authHost.get(`${process.env.VUE_APP_BASE_URL}/admin-panel/shop/team`)
      return response.data
    } catch (e) {
      console.log(e)
      return {
        success: false,
        result: null
      }
    }
  }

  async fetchRoles() {
    try {
      const response: AxiosResponse = await $authHost.get(`${process.env.VUE_APP_BASE_URL}/shop/team/roles`)
      return response.data
    } catch (e) {
      console.log(e)
      return {
        success: false,
        result: null
      }
    }
  }

  async changeAvatar(img: string, role: string) {
    try {
      const { data }: AxiosResponse<IChangeAvatarResponse> = await $authHost.put(`${process.env.VUE_APP_BASE_URL}/${role}/avatar`, {img})
      return data
    } catch (e) {
      console.log(e)
      return {
        success: false,
        result: null
      }
    }
  }

  async delAvatar(role: string) {
    try {
      await $authHost.delete(`${process.env.VUE_APP_BASE_URL}/${role}/avatar`)
    } catch (e) {
      console.log(e);
    }
  }

  async saveData(payload: ISavePayload) {
    try {
      await $authHost.put(`${process.env.VUE_APP_BASE_URL}/admin-panel/shop/team`, payload)
    } catch (e) {
      console.log(e)
    }
  }

  async cardVerification(role: string) {
    try {
      const { data }: AxiosResponse<{ success: boolean, result: string }> = await $authHost.get(`${process.env.VUE_APP_BASE_URL}/${role}/card-verification`)
      return {
        success: data.success,
        result: data.result
      }
    } catch (e) {
      console.log(e)
      return {
        success: false,
        result: ''
      }
    }
  }
}

export default new TeamService()
