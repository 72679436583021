<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12.5"
      cy="12"
      r="9.25"
      fill="white"
      stroke="#25C26E"
      stroke-width="1.5"
    />
    <path
      d="M16.0406 10.1016L12.0153 14.1094L9.6001 11.6942"
      fill="white"
    />
    <path
      d="M16.0406 10.1016L12.0153 14.1094L9.6001 11.6942"
      stroke="#25C26E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
