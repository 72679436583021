import { defineStore } from 'pinia'
import TeamService from '@/services/teamService'
import { ref } from 'vue'
import { ISavePayload } from '@/services/teamService'

  // Types
interface IChangeAvatarResponse {
  success: boolean
  result: null | TeamData
}

interface TeamData {
  id: number
  name: string
  role: string
  shop_id: number
  avatar_link: number
  purpose: string
  card_verification: boolean
  active: boolean
  avatar: string
  visible?: boolean
  card_linked: {
    id: number
    token: string
    item_id: number
    item_type: string
    number_card: string
    tips_link: string
  }
}

interface IFetchRespone {
    success: boolean
    result: null | Record<string, TeamData>
  }

  interface IRolesRespone {
    success: boolean
    result: null | IRole[]
  }

    interface IRole {
      id: number
      code: string
      name: string
    }

  export interface ITeam {
    id: number
    codeOfRole: string
    data: TeamData
  }

  interface ICardVerificationRespons {
    success: boolean
    result: string
  }

export const useTeamStore = defineStore('userStore', () => {

  // state
  const team = ref<ITeam[]>([])
  const roles = ref<IRole[]>([])
  const activeRole = ref<null | ITeam>(null)

  // Получение активного сприска команды
  const fetchTeam = async (): Promise<void> => {
    try {
      const response: IFetchRespone = await TeamService.fetch()
      if (response.success) {
        const teamsArray = []
        for (const key in response.result) {
          teamsArray.push({
            id: response.result[key].id,
            codeOfRole: key,
            data: response.result[key]
          })
        }
        team.value = teamsArray
        activeRole.value = teamsArray[0]
      }
    } catch (e) {
      console.log(e)
    }
  }


  // Список ролей
  const fetchRoles = async (): Promise<void> => {
    try {
      const response: IRolesRespone = await TeamService.fetchRoles()
      roles.value = response.result ?? []
    } catch (e) {
      console.log(e);
    }
  }

  // Получение имя роли по коду
  const getNameOfRole = (code: string) => {
    const role = roles.value.find(role => role.code === code)
    return role?.name
  }

  // Переключение активной роли
  const toggleActiveRole = (id: number) => {
    activeRole.value = team.value.find(role => role.id === id) ?? null
  }

  // Изменение аватара
  const changeAvatar = async (img: string, role: string): Promise<void> => {
    try {
      const response: IChangeAvatarResponse = await TeamService.changeAvatar(img, role)
      if (response.success) {
        team.value.forEach(person => {
          if (person.codeOfRole === role) {
            if (response.result?.avatar) {
              person.data.avatar = response.result?.avatar
            }
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Удаление аватара
  const delAvatar = async (role: string) => {
    try {
      await TeamService.delAvatar(role)
    } catch (e) {
      console.log(e)
    }
  }

  // Верификация карты
  const cardVerification = async (role: string) => {
    try {
      const response: ICardVerificationRespons = await TeamService.cardVerification(role)
      if (response.success) {
        location.href = response.result
      }
    } catch (e) {
      console.log(e)
    }
  }

  const saveTeamData = async (payload: ISavePayload) => {
    try {
      await TeamService.saveData(payload)
    } catch (e) {
      console.log(e)
    }
  }


  return {
    team,
    roles,
    activeRole,
    fetchTeam,
    fetchRoles,
    getNameOfRole,
    toggleActiveRole,
    changeAvatar,
    saveTeamData,
    delAvatar,
    cardVerification
  }
})
